.image-section {
    padding: 5vh 0;
}

.image-section-img-container {
    max-height: 60vh;
    text-align: center;
}

.image-section-img-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
}

.image-section-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 30vh;
}

.image-section-text-container {
    padding: 2rem 0 2rem 2rem !important;
}

@media only screen and (max-width: 767px) {
    .image-section-img-container {
        max-height: 40vh;
    }

    .image-section-text-container {
        padding: 2rem 15px 0 15px !important;
    }
}
