@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.card-form-section-container {
    min-height: 100vh;
    margin-bottom: 2rem;
}

.card-form-section-row {
    justify-content: center;
    margin-bottom: 10vh;
    animation: fadeIn 0.6s linear;
}

.card-form-section-col {
    margin-bottom: 1.5rem;
}

.card-form-section-title {
    margin-bottom: 1rem;
}

.card-form-section-title h1 {
    font-size: 1.75rem;
    margin: 0;
}

.card-form-section-title p {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
}

.card-form-section-button {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
}

.card-form-section-button:not(:disabled):hover {
    background-color: rgba(0, 0, 0, 0.03);
}

@media only screen and (max-width: 767px) {
    .card-form-section-button:not(:disabled):hover {
        background-color: transparent;
    }
}

.card-form-section-button:disabled * {
    opacity: 0.5;
    cursor: not-allowed;
}

.card-form-section-button:active,
.card-form-section-button.selected {
    border: 2px solid lightblue;
}

.card-form-section-button.selected::before {
    content: "\00d7";
    position: absolute;
    top: -0.25rem;
    right: 0.5rem;
    font-size: 1.5rem;
    color: lightblue;
    background-color: transparent;
}

.card-form-section-col-title {
    font-weight: 500;
    font-size: 1rem;
    color: #2f2f2f;
    margin: 0;
}

.card-form-section-col-subtitle {
    font-weight: 300;
    font-size: 0.75rem;
    color: #5b5a5a;
    margin: 0;
}

.card-form-section-footnote {
    font-weight: 300;
    font-size: 1rem;
    color: #5b5a5a;
    background-color: rgba(173, 216, 230, 0.3);
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    text-align: center;
    margin-bottom: 3rem;
}

.card-form-section-col-label {
    position: absolute;
    background-color: red;
    color: white;
    padding: 0.1rem 0.4rem;
    border-radius: 2rem;
    opacity: 1 !important;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 2;
    font-size: 0.5rem;
    font-weight: bold;
}

.card-form-section-button img {
    width: 70%;
    margin: 15% 15% 5% 15%;
}

.card-form-section-button * {
    pointer-events: none;
}