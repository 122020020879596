.pp-button {
    border: 2px solid #f15a24;
    border-radius: 0.3rem;
    font-weight: bold;
    background-color: #f15a24;
    color: white;
    padding: 0.5rem 2rem;
}

.pp-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pp-button.secondary {
    border: 2px solid transparent;
    background-color: transparent;
    color: #f15a24;
}
