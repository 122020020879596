.review-section-container {
    min-height: 100vh;
    margin-bottom: 7rem;
}

.review-section-title {
    font-size: 1.75rem;
    margin: 0;
    text-align: center;
}

.review-section-subtitle {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
}

.review-item-container {
    display: flex;
    padding: 0.5rem;
    border: 2px solid lightblue;
    border-radius: 1rem;
    margin-bottom: 1rem;
}

.review-item-image-container {
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
    text-align: center;
}

.review-item-image-container * {
    max-height: 3rem;
    max-width: 3rem;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.review-item-text-container {
    height: 3rem;
}

.review-item-text-container div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.review-item-text-title {
    font-weight: bold;
    margin: 0;
}

.review-item-text-subtitle {
    color: #5b5a5a;
    font-weight: 300;
    margin: 0;
    font-size: 0.75rem;
}

.review-section-form-label {
    color: #2f2f2f;
    font-weight: 400;
    margin: 1rem 0 0.3rem 0;
    font-size: 1rem;
}

.form-error {
    font-size: 1rem;
    font-style: italic;
    color: red;
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.result-message {
    font-weight: 300;
    font-size: 1.5rem;
    color: black;
    padding: 3rem;
    border-radius: 1rem;
    text-align: center;
    animation: fadeIn 1s linear;
}

.result-message img {
    width: 6rem;
    margin-bottom: 2rem;
}

.result-message h5 {
    margin: 0
}