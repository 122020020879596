.top-bar {
    padding: 1rem;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 10;
}

.top-bar-logo {
    height: 3rem;
}