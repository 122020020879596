.service-steps-container {
    margin: 10vh 0 10vh 0;
}

.service-steps-container ul {
    padding: 0;
    margin: 3rem 0 0 0;
    counter-reset: stepNumber;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.service-steps-container ul li {
    flex: 1 1 0px;
    display: inline-block;
    list-style-type: none;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 700;
    transition: opacity .25s linear;
    padding: 0 0.5rem;
    color: #f15a24;
}

.service-steps-container ul li::before {
    content: counter(stepNumber);
    counter-increment: stepNumber;
    height: 2rem;
    line-height: 2rem;
    font-size: 2rem;
    display: inline-block;
    text-align: left;
    margin: 0 10px 2px auto;
}

.service-steps-container ul li p {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
    color: #2f2f2f;
}

@media (max-width: 768.98px) {
    .service-steps-container ul {
        flex-flow: row wrap;
    }

    .service-steps-container ul li {
        flex: unset;
        width: 100%;
    }

    .service-steps-container ul li p {
        margin: 0.5rem 0 1.5rem 0;
    }

    .service-steps-container ul li br {
        display: none;
    }
}