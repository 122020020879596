.password-introduction-container {
    text-align: center;
    padding: 2rem;
}

.password-introduction-container img {
    height: 30vh;
}

.password-introduction-container h3 {
    font-size: 1.5rem;
    margin-top: 2rem;
}

.password-introduction-container input {
    display: block;
    width: 50%;
    margin: 0 auto;
}

@media (max-width: 768.98px) { 
    .password-introduction-container input {
        width: 100%;
    }
}