.center-text-section-container {
    text-align: center;
    padding: 10vh 0 0 0;
}

.center-text-section-title {
    font-size: 2rem;
    font-weight: bold;
}

.center-text-section-subtitle {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
    color: #2f2f2f;
}
