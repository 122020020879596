.password-introduction-container {
    text-align: center;
    padding: 2rem;
}

.password-introduction-container img {
    height: 30vh;
}

.password-introduction-container h3 {
    font-size: 1.5rem;
    margin-top: 2rem;
}

.password-introduction-container input {
    display: block;
    width: 50%;
    margin: 0 auto;
}

@media (max-width: 768.98px) { 
    .password-introduction-container input {
        width: 100%;
    }
}
.pp-button {
    border: 2px solid #f15a24;
    border-radius: 0.3rem;
    font-weight: bold;
    background-color: #f15a24;
    color: white;
    padding: 0.5rem 2rem;
}

.pp-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pp-button.secondary {
    border: 2px solid transparent;
    background-color: transparent;
    color: #f15a24;
}

.hero-section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-section-col {
    min-height: 70vh;
    text-align: left;
}

.hero-section-text-container {
    position: relative;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    color: white;
}

.hero-section-text-container .hero-title {
    font-weight: bold;
}

.hero-section-text-container .hero-subtitle {
    margin: 0.5rem 0 2rem 0;
    font-weight: normal;
}

.hero-section-row img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hero-children {
    margin-top: 3rem;
}

@media only screen and (max-width: 767px) {
    .hero-section-col {
        min-height: 50vh;
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .hero-section-col img {
        padding: 0 0 3rem 0;
        max-height: 30vh;
        width: unset;
    }

    .hero-children {
        margin-top: 6rem;
    }
}
.service-steps-container {
    margin: 10vh 0 10vh 0;
}

.service-steps-container ul {
    padding: 0;
    margin: 3rem 0 0 0;
    counter-reset: stepNumber;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.service-steps-container ul li {
    flex: 1 1;
    display: inline-block;
    list-style-type: none;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 700;
    transition: opacity .25s linear;
    padding: 0 0.5rem;
    color: #f15a24;
}

.service-steps-container ul li::before {
    content: counter(stepNumber);
    counter-increment: stepNumber;
    height: 2rem;
    line-height: 2rem;
    font-size: 2rem;
    display: inline-block;
    text-align: left;
    margin: 0 10px 2px auto;
}

.service-steps-container ul li p {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
    color: #2f2f2f;
}

@media (max-width: 768.98px) {
    .service-steps-container ul {
        flex-flow: row wrap;
    }

    .service-steps-container ul li {
        flex: unset;
        width: 100%;
    }

    .service-steps-container ul li p {
        margin: 0.5rem 0 1.5rem 0;
    }

    .service-steps-container ul li br {
        display: none;
    }
}
.image-section {
    padding: 5vh 0;
}

.image-section-img-container {
    max-height: 60vh;
    text-align: center;
}

.image-section-img-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
}

.image-section-img {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 30vh;
}

.image-section-text-container {
    padding: 2rem 0 2rem 2rem !important;
}

@media only screen and (max-width: 767px) {
    .image-section-img-container {
        max-height: 40vh;
    }

    .image-section-text-container {
        padding: 2rem 15px 0 15px !important;
    }
}

.top-bar {
    padding: 1rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 10;
}

.top-bar-logo {
    height: 3rem;
}
.pp-progress-bar {
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 1rem;
    border-top: 1px solid lightblue;
}

.pp-progress-bar-steps {
    display: inline-block;
    padding: 0;
    margin: 0;
    counter-reset: stepNumber;
}

.pp-progress-bar-step {
    display: inline-block;
    position: relative;
    list-style-type: none;
    text-align: center;
    font-size: .75rem;
    margin-right: 30px;
    text-transform: uppercase;
    font-weight: 700;
    opacity: .5;
    transition: opacity .25s linear;
}

.pp-progress-bar-step::before {
    content: counter(stepNumber);
    counter-increment: stepNumber;
    width: 34px;
    height: 34px;
    line-height: 30px;
    border: 2px solid lightslategray;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin: 0 10px 2px auto;
}

.pp-progress-bar-step.current {
    opacity: 1;
}

.pp-progress-bar-step.selectable {
    cursor: pointer;
    opacity: 1;
}

.pp-progress-bar-step.current::before {
    background-color: #f15a24;
    border: 2px solid #f15a24;
    color: #fff;
}

@media (max-width: 768.98px) {
    .pp-progress-bar {
        display: block;
    }
    
    .pp-progress-bar-steps {
        display: flex;
        padding: 0 0 1rem 0;
        margin: 0;
        counter-reset: stepNumber;
        justify-content: space-between;
    }

    .pp-progress-bar-step {
        display: flex;
        margin: 0 2px;
        width: 100%;
        height: 4px;
        border-radius: 0;
        border: 2px solid lightslategray;
    }

    .pp-progress-bar-step.current {
        background-color: #f15a24;
        border: 2px solid #f15a24;
    }

    .pp-progress-bar-step::before {
        content: none;
    }

    .pp-progress-bar-step span {
        display: none;
    }

    .pp-progress-bar-buttons {
        text-align: center;
    }
}

@-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.card-form-section-container {
    min-height: 100vh;
    margin-bottom: 2rem;
}

.card-form-section-row {
    justify-content: center;
    margin-bottom: 10vh;
    -webkit-animation: fadeIn 0.6s linear;
            animation: fadeIn 0.6s linear;
}

.card-form-section-col {
    margin-bottom: 1.5rem;
}

.card-form-section-title {
    margin-bottom: 1rem;
}

.card-form-section-title h1 {
    font-size: 1.75rem;
    margin: 0;
}

.card-form-section-title p {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
}

.card-form-section-button {
    width: 100%;
    height: 100%;
    border: none;
    background: none;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    border: 2px solid transparent;
}

.card-form-section-button:not(:disabled):hover {
    background-color: rgba(0, 0, 0, 0.03);
}

@media only screen and (max-width: 767px) {
    .card-form-section-button:not(:disabled):hover {
        background-color: transparent;
    }
}

.card-form-section-button:disabled * {
    opacity: 0.5;
    cursor: not-allowed;
}

.card-form-section-button:active,
.card-form-section-button.selected {
    border: 2px solid lightblue;
}

.card-form-section-button.selected::before {
    content: "\D7";
    position: absolute;
    top: -0.25rem;
    right: 0.5rem;
    font-size: 1.5rem;
    color: lightblue;
    background-color: transparent;
}

.card-form-section-col-title {
    font-weight: 500;
    font-size: 1rem;
    color: #2f2f2f;
    margin: 0;
}

.card-form-section-col-subtitle {
    font-weight: 300;
    font-size: 0.75rem;
    color: #5b5a5a;
    margin: 0;
}

.card-form-section-footnote {
    font-weight: 300;
    font-size: 1rem;
    color: #5b5a5a;
    background-color: rgba(173, 216, 230, 0.3);
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    text-align: center;
    margin-bottom: 3rem;
}

.card-form-section-col-label {
    position: absolute;
    background-color: red;
    color: white;
    padding: 0.1rem 0.4rem;
    border-radius: 2rem;
    opacity: 1 !important;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 2;
    font-size: 0.5rem;
    font-weight: bold;
}

.card-form-section-button img {
    width: 70%;
    margin: 15% 15% 5% 15%;
}

.card-form-section-button * {
    pointer-events: none;
}
.review-section-container {
    min-height: 100vh;
    margin-bottom: 7rem;
}

.review-section-title {
    font-size: 1.75rem;
    margin: 0;
    text-align: center;
}

.review-section-subtitle {
    margin-top: 0.5rem;
    font-size: 1rem;
    font-weight: 300;
    text-align: center;
}

.review-item-container {
    display: flex;
    padding: 0.5rem;
    border: 2px solid lightblue;
    border-radius: 1rem;
    margin-bottom: 1rem;
}

.review-item-image-container {
    height: 3rem;
    width: 3rem;
    margin-right: 2rem;
    text-align: center;
}

.review-item-image-container * {
    max-height: 3rem;
    max-width: 3rem;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.review-item-text-container {
    height: 3rem;
}

.review-item-text-container div {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.review-item-text-title {
    font-weight: bold;
    margin: 0;
}

.review-item-text-subtitle {
    color: #5b5a5a;
    font-weight: 300;
    margin: 0;
    font-size: 0.75rem;
}

.review-section-form-label {
    color: #2f2f2f;
    font-weight: 400;
    margin: 1rem 0 0.3rem 0;
    font-size: 1rem;
}

.form-error {
    font-size: 1rem;
    font-style: italic;
    color: red;
}

@-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}

.result-message {
    font-weight: 300;
    font-size: 1.5rem;
    color: black;
    padding: 3rem;
    border-radius: 1rem;
    text-align: center;
    -webkit-animation: fadeIn 1s linear;
            animation: fadeIn 1s linear;
}

.result-message img {
    width: 6rem;
    margin-bottom: 2rem;
}

.result-message h5 {
    margin: 0
}
.footer-container {
    padding: 2rem 0;
}

.footer-container hr {
    margin-bottom: 2rem;
    background-color: #f15a24;
    opacity: 1;
}

.footer-logo {
    text-align: center;
}

.footer-logo img {
    height: 4rem;
}

.center-text-section-container {
    text-align: center;
    padding: 10vh 0 0 0;
}

.center-text-section-title {
    font-size: 2rem;
    font-weight: bold;
}

.center-text-section-subtitle {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 300;
    color: #2f2f2f;
}


body {
    margin: 0;
    font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', monospace;
}
  
