.footer-container {
    padding: 2rem 0;
}

.footer-container hr {
    margin-bottom: 2rem;
    background-color: #f15a24;
    opacity: 1;
}

.footer-logo {
    text-align: center;
}

.footer-logo img {
    height: 4rem;
}
