.hero-section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-section-col {
    min-height: 70vh;
    text-align: left;
}

.hero-section-text-container {
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    color: white;
}

.hero-section-text-container .hero-title {
    font-weight: bold;
}

.hero-section-text-container .hero-subtitle {
    margin: 0.5rem 0 2rem 0;
    font-weight: normal;
}

.hero-section-row img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hero-children {
    margin-top: 3rem;
}

@media only screen and (max-width: 767px) {
    .hero-section-col {
        min-height: 50vh;
        padding-top: 4rem !important;
        padding-bottom: 4rem !important;
    }

    .hero-section-col img {
        padding: 0 0 3rem 0;
        max-height: 30vh;
        width: unset;
    }

    .hero-children {
        margin-top: 6rem;
    }
}