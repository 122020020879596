.pp-progress-bar {
    display: flex;
    justify-content: space-between;
    background-color: white;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 1rem;
    border-top: 1px solid lightblue;
}

.pp-progress-bar-steps {
    display: inline-block;
    padding: 0;
    margin: 0;
    counter-reset: stepNumber;
}

.pp-progress-bar-step {
    display: inline-block;
    position: relative;
    list-style-type: none;
    text-align: center;
    font-size: .75rem;
    margin-right: 30px;
    text-transform: uppercase;
    font-weight: 700;
    opacity: .5;
    transition: opacity .25s linear;
}

.pp-progress-bar-step::before {
    content: counter(stepNumber);
    counter-increment: stepNumber;
    width: 34px;
    height: 34px;
    line-height: 30px;
    border: 2px solid lightslategray;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    margin: 0 10px 2px auto;
}

.pp-progress-bar-step.current {
    opacity: 1;
}

.pp-progress-bar-step.selectable {
    cursor: pointer;
    opacity: 1;
}

.pp-progress-bar-step.current::before {
    background-color: #f15a24;
    border: 2px solid #f15a24;
    color: #fff;
}

@media (max-width: 768.98px) {
    .pp-progress-bar {
        display: block;
    }
    
    .pp-progress-bar-steps {
        display: flex;
        padding: 0 0 1rem 0;
        margin: 0;
        counter-reset: stepNumber;
        justify-content: space-between;
    }

    .pp-progress-bar-step {
        display: flex;
        margin: 0 2px;
        width: 100%;
        height: 4px;
        border-radius: 0;
        border: 2px solid lightslategray;
    }

    .pp-progress-bar-step.current {
        background-color: #f15a24;
        border: 2px solid #f15a24;
    }

    .pp-progress-bar-step::before {
        content: none;
    }

    .pp-progress-bar-step span {
        display: none;
    }

    .pp-progress-bar-buttons {
        text-align: center;
    }
}
